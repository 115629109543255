<template>
  <div>
    <b-row cols="8">
      <b-col lg="3" sm="2" md="3" v-for="(item, idx) in list" :key="idx">
        <b-link :to="item.to">
          <b-card class="text-center">
            <feather-icon
              size="50"
              :icon="item.icon"
              :class="`text-${item.iconColor} my-2`"
            />

            <div :class="`mb-25 font-weight-bolder text-primary`">
              {{ item.text }}
            </div>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BAvatar, BRow, BCol, BCard, BCardText, BLink } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
  },

  data() {
    return {
      list: [        
        {
          icon: "EyeIcon",
          text: "隐私政策",
          to: "/enterprise/policy",
          iconColor: "success",
          bgColor: "info",
          textColor: "white",
        },
        {
          icon: "BookOpenIcon",
          text: "合规培训",
          to: "/training-course",
          iconColor: "primary",
          bgColor: "primary",
          textColor: "white",
        },
        {
          icon: "MapIcon",
          text: "数据地图",
          to: "/datamap-process",
          iconColor: "danger",
          bgColor: "secondary",
          textColor: "white",
        },
        {
          icon: "FileTextIcon",
          text: "隐私数据清单",
          to: "/datamap-fields",
          iconColor: "primary",
          bgColor: "success",
          textColor: "white",
        },
        {
          icon: "KeyIcon",
          text: "PIA管理",
          to: "/pia-analysis",
          iconColor: "success",
          bgColor: "danger",
          textColor: "white",
        },
        {
          icon: "LayersIcon",
          text: "知识库",
          to: "/labrary/law",
          iconColor: "primary",
          bgColor: "warning",
          textColor: "white",
        },
        {
          icon: "UsersIcon",
          text: "同意及偏好管理",
          to: "/cpm-channel",
          iconColor: "danger",
          bgColor: "info",
          textColor: "white",
        },
        {
          icon: "ClipboardIcon",
          text: "Cookie管理",
          to: "/cookies-template",
          iconColor: "primary",
          bgColor: "primary",
          textColor: "white",
        },
        {
          icon: "CpuIcon",
          text: "产品管理",
          to: "/product",
          iconColor: "success",
          bgColor: "secondary",
          textColor: "white",
        },
        {
          icon: "BookIcon",
          text: "案例库",
          to: "/labrary/case",
          iconColor: "primary",
          bgColor: "success",
          textColor: "white",
        },
        {
          icon: "EyeIcon",
          text: "法律合规管理",
          to: "/compliance-list",
          iconColor: "danger",
          bgColor: "danger",
          textColor: "white",
        },
        {
          icon: "PieChartIcon",
          text: "统计分析",
          iconColor: "primary",
          bgColor: "warning",
          textColor: "white",
          to: "/statistics",
        },
      ],
    };
  },
};
</script>

<style>
</style>
